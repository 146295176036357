#dashboard {
  .apiary-bee-item {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }
  .apiary-bee {
    width: auto;
    height: auto;
    position: absolute;
    z-index: 6;

    &.apiary-bee-1 {
      width: 11.5%;
      left: 13.2%;
      bottom: 8.9%;
    }
    &.apiary-bee-2 {
      width: 7.15%;
      left: 21.4%;
      bottom: 32%;
    }
    &.apiary-bee-3 {
      width: 8.6%;
      right: 8.42%;
      bottom: 32.91%;
    }
    &.apiary-bee-4 {
      width: 10.7%;
      right: 26.8%;
      bottom: 29.1%;
    }
    &.apiary-bee-5 {
      width: 9.42%;
      right: 16.42%;
      bottom: 6.96%;
    }
    &.apiary-bee-6 {
      width: 11.07%;
      right: 23.21%;
      top: 6.32%;
    }
    &.apiary-bee-7 {
      width: 10.35%;
      left: 42.71%;
      top: 27.21%;
    }
  }
}
