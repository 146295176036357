body, html, #root {
  height: 100%;
}

body {
  //background: url("../assets/images/BG.png") center bottom no-repeat;
  //background-size: cover;
}

svg,
img {
  max-width: 100%;
  height: auto;
}

.preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  backdrop-filter: blur(3px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  z-index: 100;
}

.cur-pointer {
  cursor: pointer;
}

#walletconnect-wrapper .walletconnect-modal__mobile__toggle a {
  color: #333;
}

.nft-image {
  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.card-bee {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 5px;
  top: -10px;
}

#card-create-apiary {
  width: 1000px;
  max-width: 100%;
  line-height: 1.3;

  .d-flex {
    img {
      width: 285px;
      max-width: 100%;
    }

    z-index: 5;
  }

  .apiary-card-svg {
    img {
      position: absolute;
      z-index: 4;
    }

    .svg-top {
      width: 580px;
      max-width: 80%;
      left: 355px;
      top: 0;
    }
    .svg-bottom {
      width: 485px;
      max-width: 80%;
      left: 250px;
      bottom: 0;
    }

    @include media-breakpoint-down(lg) {
      .svg-top {
        left: 5%;
      }
      .svg-bottom {
        left: 10%;
      }
    }
  }
}

.wide-buttons {
  flex-wrap: nowrap;

  .btn {
    min-width: 120px;

    &.btn-success,
    &.btn-primary {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

#welcome-box-button {;
  transform: scale(1.1) rotate(0);
  animation: WelcomeBoxAnimation 1s ease-in-out infinite;
}

@keyframes WelcomeBoxAnimation {
  0% {
    transform: scale(1.1) rotate(0);
  }
  10% {
    transform: scale(1.1) rotate(0);
  }
  20% {
    transform: scale(1.1) rotate(5deg);
  }
  40% {
    transform: scale(1.1) rotate(-5deg);
  }
  60% {
    transform: scale(1.1) rotate(5deg);
  }
  80% {
    transform: scale(1.1) rotate(-5deg);
  }
  90% {
    transform: scale(1.1) rotate(0);
  }
  100% {
    transform: scale(1.1) rotate(0);
  }
}
